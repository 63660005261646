.Header {
  border-top: 1px solid #00000020;
  height: 40px !important;
}

.Footer {
  background: #f8b415 !important;
}

.p-dialog-mask {
  z-index: 3000 !important;
}

.p-image-mask {
  z-index: 3100 !important;
  background-color: #212121e0 !important;
}

.p-image-toolbar {
  margin: 20px;
  column-gap: 10px;
}

.p-image-toolbar>.p-image-action>i {
  color: white;
  font-size: 20px;
}

.p-button {
  padding: 5px 10px 5px 10px;
  font-size: 13px !important;
}

.p-button-icon {
  padding-right: 7px;
}

.p-toast {
  top: 15px !important;
  width: 400px !important;
  max-width: 50vw !important;
}

.p-toast-message-text {
  padding: 25px 5px 25px 5px !important;
}

.p-toast-message-icon {
  font-size: 40px !important;
  padding: 10px 40px 10px 15px !important;
  align-self: center !important;
  justify-self: center !important;
}

.p-toast-icon-close-icon {
  color: white !important;
  padding: 15px !important;
  font-size: 17px !important;
}

.p-toast-summary {
  font-weight: 600;
  font-size: 16px;
}

.pi-spinner{
  padding-right: 0px;
}

body>.p-dropdown-panel{
  z-index: 10000 !important;
}

.p-button-icon-only>.pi-question-circle{
  padding-right: 0;
}

.p-component,
.p-component *,
.p-skeleton,
.skeleton * {
    box-sizing: border-box;
}

.p-skeleton {
    background-color: #e9ecef;
    border-radius: 3px;
}
.p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-datatable-scrollable-header-box {
  margin-right: 0 !important;
}


.Table-Header-Cell {
  /*background: #f8b415 !important;*/
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #ff980090 !important;
  background: #f8b415 !important;
  /*height: 0px !important;*/
}

.cell-header {
  display: flex !important;
  justify-content: center !important;
}

.Header svg {
  color: #ff9800 !important;
}

.Button-Border {
  box-shadow: none !important;
  margin: 4px !important;
}

.SnackbarItem-lessPadding-332 {
  background-color: #f8b415 !important;
}

.gXQQfT {
  text-align: center !important;
}

.virtualized-container {
  background-color: white !important;
}

.Table-Row {
  background-color: transparent !important;
}

.virtualized-container>div>div:nth-child(even) {
  background-color: #f2f2f2 !important;
}

.title {
  margin-right: unset !important;
  width: 100% !important;
  justify-content: center !important;
  font-weight: bold !important;
  padding-left: 125px !important;
}

/* Dashboard */
.MuiTabs-flexContainer {
  background-color: white !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #f8b415 !important;
}

@media (min-width: 700px) {
  .col-xl-ajt {
    width: calc(100% / 8) !important;
  }
}

.MuiTab-wrapper {
  font-size: 12px;
}

.MuiTab-wrapper {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.MuiTab-wrapper svg {
  margin-left: 4px;
  height: 18px;
}

.Table-Cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.Table-Row {
  height: 0px !important;
}

$n: 100;

.virtualized-container {
  height: 455px !important;

  >div {
    height: 400px !important;

    @for $i from 1 through $n {
      >div:nth-child(#{$i}) {
        height: 40px !important;
        top: calc((#{$i} - 1) * 40px) !important;

        >div {
          height: 40px !important;
        }
      }
    }
  }
}

.Table-Header-Cell.action>div {
  width: 120px !important;
}

.Table-Cell.action>div {
  width: 120px !important;
}

.virtualized-container>div>div:nth-child(even),
.virtualized-container>div>div:nth-child(even)>.Table-Row>.Table-Cell.action,
.virtualized-container>div>div:nth-child(even)>.Table-Row {
  background-color: #f2f2f2 !important;
}

.p-multiselect {
  width: 100% !important;
  height: 30px !important;
}

.p-multiselect-label {
  white-space: nowrap !important;
  max-width: 595px !important;
}

.p-calendar>input {
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.p-inputtext {
  width: 100% !important;
  // height: 30px !important ;
}

body .p-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 30px;
}

.p-placeholder {
  font-size: 12px !important;
  align-items: center !important;
  display: flex !important;
}

// Mais filtros
.MuiAccordionDetails-root {
  background-color: #f2f2f2;
}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  background-color: #00000020;
  min-height: 0px !important;
  height: 30px !important;
  border-radius: 4px !important;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  background-color: #00000030;
  height: 30px !important;
  min-height: 0px !important;
  border-radius: 4px !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  min-width: 50% !important;
}

.p-dropdown label.p-dropdown-label {
  font-size: 13px !important;
  height: 28px !important;
}

.p-dropdown {
  height: 30px !important;
}

//Alinhamento da tela de perfil
.p-fieldset-content {
  display: flex;
  min-width: 400px;
  width: 100%;
}

body .p-dialog .p-dialog-header {
  border: 0 none;
  background-color: #f8b415;
  color: #212121;
  padding: 0.5em;
  font-weight: 700;
  font-size: 1.286em;
}

body .p-dialog {
  padding: 0;
  background-color: #ffffff;
}

.titulo {
  width: 100%;
  min-width: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  padding-bottom: 10px;
  font-size: 20px;
  padding-left: 0px;
  font-weight: bold !important;
}

.customTooltipApexChart {
  box-shadow: 0px 0px 10px rgb(46, 46, 46);
}

.customTooltipApexChartTop {
  padding: 10px;
  background-color: #ECEFF1;
  font-size: 14px;
}

.customTooltipApexChartBody {
  padding: 10px;
  font-size: 14px;
}

.customTooltipApexChartBody div {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: left;
}

.customTooltipApexChartLabelColorBlue {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #25A0FC;
}

.customTooltipApexChartLabelColorCyan {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #26E7A6;
}

.customTooltipApexChartLabelColorYellow {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #FEBC3B;
}

.histogram-infos {
  padding: 10px;
  width: max-content;
  background-color: #ffee90;
  color: #313131;
  font-family: "cabin", sans-serif;
  border-radius: 5px;
  line-height: 14px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 40px;
}

body .p-inputtext:enabled:hover {
  border-color: #bdbdbd !important;
}

.km-filter {
  -moz-appearance: textfield;
  appearance: textfield;
}

.km-filter::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.p-dropdown-filter-container {
  width: 100%;
}